
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import { FileTextOutlined } from "@ant-design/icons-vue";
import BatchConfirmModal from "../components/batchConfirmModal.vue";
import TableWrapper from "@/views/Payment/components/TableWrapper.vue";
import searchTitle from "../components/searchTitle.vue";
import useSampleCheckDealer from "@/hooks/checking/spotChecking/sampleCheck/useSampleCheckDealer";
import {
  spotCheckSampledOptions,
  controllingSampledOptions,
  checkStatusOptions,
} from "../components/selectOptions";
import downloadFile from "@/utils/payment/downloadFile";
import uploadFile from "../components/uploadFileDealer.vue";
import { message } from "ant-design-vue";
import {
  exportSupportDoc,
  downloadSupportDoc,
} from "@/API/checking/spotChecking";
import { downloadFromUrls } from "@/utils/payment/downloadFile";
export default defineComponent({
  name: "App",
  props: {},
  setup() {
    const isOpen = ref<boolean>(false);
    const {
      column,
      data,
      tableSelection,
      query,
      summary,
      getTableData,
      pagination,
      packageCode,
      ids,
      pageChange,
      sizeChange,
      getOfferTypeList,
      offerTypeList,
      exportDemoCarBtnVisibility,
      handleExportDemoCar,
    } = useSampleCheckDealer();
    const init = () => {
      getOfferTypeList();
      getTableData();
    };
    init();
    const search = () => {
      getTableData();
    };
    const reset = () => {
      query.checkStatus = [];
      query.queryDealerTrade = "";
      query.dealerCode = "";
      query.dealerName = "";
      query.model = "";
      query.programCode = "";
      query.programName = "";
      query.spotCheckSampled = [];
      query.vin = "";
      query.offerType = "";
      search();
    };
    const batchConfirmRef = ref();
    const handleBatchConfirmShow = () => {
      batchConfirmRef.value.search();
    };
    const offerTypeIds = computed(() => {
      return tableSelection.selectedRowData.map((item: any) => {
        return item.packageProgramOfferTypeId;
      });
    });
    const handleConfirm = () => {
      search();
    };
    const handleExportSampleData = () => {
      const params = {
        url: `/claimapi/SpotChecking/Checking/downloadDealerSampleCheckResult/${query.packageCodeId}`,
        // url: '/claimapi/SpotChecking/Checking/downloadDealerSampleCheckResult/887375879406227460',
        method: "get",
      };
      downloadFile(params).catch((res) => {
        if (res.message) {
          message.warning(res.message);
        } else {
          message.warning(res);
        }
      });
    };
    // const handleExportSupportDoc = () => {
    //   const params = {
    //     url: `/claimapi/SpotChecking/Checking/export/support/doc?packageCodeId=${query.packageCodeId}`,
    //     method: "post",
    //   };
    //   downloadFile(params).catch((res) => {
    //     if (res.message) {
    //       message.warning(res.message);
    //     } else {
    //       message.warning(res);
    //     }
    //   });
    // };
    const handleExportSupportDoc = () => {
      exportSupportDoc(query.packageCodeId).then((data) => {
        const errorItem = data?.find((item: any) => item.code !== "1");
        if (errorItem) {
          message.warning(errorItem.message);
        }
        const urls =
          data
            ?.filter((item: any) => item.code === "1")
            .map((item: any) => item.zipDownloadUrl) ?? [];
        downloadFromUrls(urls);
      });
    };
    const handleDownload = (record: any) => {
      // if (!record.spotSampled) {
      //   return;
      // }
      downloadSupportDoc({
        claimId: record.claimId,
        vin: record.vin,
      }).then((res: any) => {
        if (res && res.code === "0") {
          window.location.href = res.data.downloadUrl;
        } else {
          message.warning(res.data.message);
        }
      });
    };
    // const handleDownload = (record: any) => {
    //   if (!record.spotSampled) {
    //     return;
    //   }
    //   downloadSupportDoc(query.packageCodeId, record.programCode).then(
    //     (data) => {
    //       if (data && data[0].code === "1") {
    //         window.location.href = data[0].zipDownloadUrl;
    //       } else {
    //         message.warning(data[0].message);
    //       }
    //     }
    //   );
    // };
    // const handleDownload = (record: any) => {
    //   if (!record.spotSampled) {
    //     return
    //   }
    //   const params = {
    //     url: `/claimapi/SpotChecking/Checking/download/support/doc?packageCodeId=${query.packageCodeId}&programCode=${record.programCode}`,
    //     method: "post",
    //   };
    //   downloadFile(params).catch((res) => {
    //     if (res.message) {
    //       message.warning(res.message);
    //     } else {
    //       message.warning(res);
    //     }
    //   });
    // };

    const uploadVisible = ref(false);
    const handleUpload = () => {
      uploadVisible.value = true;
    };
    const handleTableChange = () => {
      search();
    };
    const closeUpload = (param: { isUploadVisible: boolean }) => {
      uploadVisible.value = param.isUploadVisible;
    };
    return {
      column,
      data,
      packageCode,
      ids,
      tableSelection,
      query,
      ...toRefs(query),
      summary,
      reset,
      search,
      isOpen,
      ...toRefs(pagination),
      isPagination: true,
      spotCheckSampledOptions,
      controllingSampledOptions,
      checkStatusOptions,
      batchConfirmRef,
      handleBatchConfirmShow,
      pageChange,
      sizeChange,
      offerTypeList,
      offerTypeIds,
      handleExportSampleData,
      handleExportSupportDoc,
      handleDownload,
      handleUpload,
      uploadVisible,
      handleTableChange,
      handleConfirm,
      closeUpload,
      exportDemoCarBtnVisibility,
      handleExportDemoCar,
    };
  },
  components: {
    searchTitle,
    TableWrapper,
    FileTextOutlined,
    BatchConfirmModal,
    uploadFile,
  },
});
