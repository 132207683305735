import { ref, reactive, computed, onMounted, toRaw } from "vue";
import {
  getSampleCheckDetailDealerList,
  isExportDemoCar,
  exportDemoCar
} from "@/API/checking/spotChecking";
import { getIncentiveTypeDropDownSelectList } from "@/API/spotCheckingConfiguration";
import { ColumnProps } from "ant-design-vue/es/table/interface";
import { useStore} from 'vuex'
import useThousandsAndTwoDecimal from "@/utils/payment/useThousandsAndTwoDecimal";
import useOverflowText from "@/utils/payment/useOverflowText";
import { downloadFromStream } from "@/utils/payment/downloadFile";
type Key = ColumnProps["key"];
const useControllingChecked = () => {
  const column = ref([
    { title: "Program Name(EN)", dataIndex: "programName", customRender: useOverflowText(), width: 120 },
    { title: "Program Code", dataIndex: "programCode", width: 120 },
    { title: "SI Offer", dataIndex: "siOffer", width: 120 },
    { title: "Offer Type", dataIndex: "offerType", width: 120 },
    { title: "Dealer Code", dataIndex: "dealerCode", width: 120 },
    { title: "Dealer Name(EN)", dataIndex: "dealerName", customRender: useOverflowText(), width: 120 },
    { title: "VIN", dataIndex: "vin", width: 120 },
    { title: "Model Name", dataIndex: "modelName", width: 120 },
    { title: "Dealer Trade", dataIndex: "dealerTrade", width: 120 },
    { title: "Claim Amount", dataIndex: "claimAmount", customRender: useThousandsAndTwoDecimal(), width: 120 },
    { title: "Spot Sampled", slots: { customRender: "spotSampled" }, width: 120 },
    {
      title: "Con. Reject History",
      slots: { customRender: "conRejectHistory" },
      width: 120
    },
    { title: "Spot Check Result", dataIndex: "spotCheckResult", width: 120 },
    { title: "Sample Check Result", dataIndex: "sampleCheckResult", width: 120 },
    { title: "Check Status", dataIndex: "checkStatus", width: 120 },
    { title: "Operation", slots: { customRender: "operation" }, width: 150 },
  ]);
  const query = reactive({
    checkStatus: [],
    queryDealerTrade: "",
    dealerCode: "",
    dealerName: "",
    model: "",
    packageCodeId: "",
    programCode: "",
    programName: "",
    spotCheckSampled: [],
    offerType: '',
    vin: "",
  });
  // 分页
  const pagination = reactive({
    pageNumber: 1,
    pageSize: 10,
    total: 0
  });
  // 是否显示Export Demo Car按钮
  const exportDemoCarBtnVisibility = ref(false)
  const { state } = useStore()
  query.packageCodeId = state.spotChecking.sampleCheckPackagecodeId
  // query.packageCodeId = '882205713374515202'
  const dataRes = ref();
  const data = computed(() => {
    return dataRes.value?.content
      ? dataRes.value?.content
      : [];
  });
  const packageCode = computed(() => {
    // return  dataRes.value?.content[0].packageCode
    return state.spotChecking.sampleCheckPackagecode
  })
  const summary = computed(() => {
    const obj = {};
    if (dataRes.value) {
      for (const key in dataRes.value) {
        if (key !== "spotCheckVinRespVOList") {
          obj[key] = dataRes.value[key];
        }
      }
    }
    return obj;
  });
  const getTableData = () => {
    // const _query: any = { ...toRaw(query) };
    // _query.rejectHistory = _query.rejectHistory ? "-1" : "-1";
    const _query = {
      ...toRaw(query),
      pageNumber: pagination.pageNumber,
      pageSize: pagination.pageSize
    }
    getSampleCheckDetailDealerList(_query).then((res) => {
      dataRes.value = res;
      pagination.total = res ? res.totalElements : 0
    });
  };
  
  // 复选框
  const tableSelection = reactive<{
    selectedRowKeys: Key[];
    selectedRowData: any[];
    // onSelectAll: Function;
    onChange: Function;
  }>({
    onChange: (selectedRowKeys: string[], selectedRowData: any[]) => {
      tableSelection.selectedRowKeys = selectedRowKeys;
      tableSelection.selectedRowData = selectedRowData;
    },
    // onSelectAll: (selected: boolean) => {
    //   if (selected) {
    //     tableSelection.selectedRowKeys = data.value.map((item: any) => item.id);
    //   } else {
    //     tableSelection.selectedRowKeys = [];
    //   }
    // },
    selectedRowKeys: [],
    selectedRowData: []
  });
  const ids = computed(() => {
    return tableSelection.selectedRowData.map((item: any) => item.packageProgramId)
  })
  // 分页方法
  const pageChange = (page: number, size: number) => {
    pagination.pageNumber = page > 0 ? page : 1;
    pagination.pageSize = size;
    getTableData();
  };
  const sizeChange = (page: number, size: number) => {
    pagination.pageNumber = page > 0 ? page : 1;
    pagination.pageSize = size;
    getTableData();
  };
  // offer type list
  const offerTypeRes = ref();
  const offerTypeList = computed(() => {
    if (offerTypeRes.value && offerTypeRes.value.incentiveCategory && Array.isArray(offerTypeRes.value.incentiveCategory)) {
      const arr: any = []
      offerTypeRes.value.incentiveCategory.forEach((item: any) => {
        if (item.offerType && Array.isArray(item.offerType)) {
          arr.push(item.offerType)
        }
      })
      return Array.from(new Set(arr.flat().map((item: any) => item.nameEn)))
    } else {
      return []
    }
  });
  const getOfferTypeList = () => {
    getIncentiveTypeDropDownSelectList().then((res) => {
      offerTypeRes.value = res;
    });
  };

  isExportDemoCar(state.spotChecking.sampleCheckPackagecodeId).then((showExport: boolean) => {
    exportDemoCarBtnVisibility.value = showExport
  })

  const handleExportDemoCar = async () => {
    const res = await exportDemoCar(state.spotChecking.sampleCheckPackagecodeId)
    downloadFromStream(res)
  }

  return {
    data,
    dataRes,
    column,
    query,
    summary,
    pagination,
    getTableData,
    tableSelection,
    packageCode,
    ids,
    pageChange,
    sizeChange,
    getOfferTypeList,
    offerTypeList,
    exportDemoCarBtnVisibility,
    handleExportDemoCar
  };
};
export default useControllingChecked;
